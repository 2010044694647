.modal-subtitle {
  font-family: "Gill Sans", sans-serif;
  font-weight: 200;
  line-height: 1.35;
  color: $davys-grey;
  font-size: 2rem;
}

.modal-text {
  font-family: "Gill Sans", sans-serif;
  font-size: 1rem;
}

.test-img {
  width: 200px;
  height: 200px;
}

.container-permablok3 {
  position: relative;
}

.image-permablok3 {
  left: 82%;
  top: 90%;
}
