.title-underlined {
  position: relative;
  color: $black;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;

  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 1px;
    position: absolute;
    bottom: 0;
    background-color: $black;
  }
}

.title-underlined--align-left {
  text-align: left;

  &:after {
    left: 0;
  }
}

.title-underlined--align-center {
  text-align: center;

  &:after {
    left: 50%;
    margin-left: -50px;
  }
}

.title-underlined--align-right {
  text-align: right;

  &:after {
    right: 0;
  }
}

.title-underlined--color-white {
  color: $white;

  &:after {
    background-color: $white;
  }
}

.title-underlined--color-black {
  color: $black;

  &:after {
    background-color: $black;
  }
}
