@include media-breakpoint-down(sm) {
  .colors-slider {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.1rem 1.5rem;
  }

  .colors-slider__title {
    display: none;
  }

  .slider-arrow {
    fill: $white;
  }
}
