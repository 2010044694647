.modal-title {
  font-family: "Dala Moa", sans-serif !important;
  color: $secondary;
  font-size: 2rem;
}

.text-how-works {
  font-family: "Gill Sans MT" !important;
  color: $secondary !important;
  font-size: 1.5rem !important;
}

.w-64 {
  width: 64%;
}
