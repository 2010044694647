@include media-breakpoint-down(sm) {
  .icon-container {
    height: 10px;
  }

  .hero {
    padding-top: $header-height;
    height: 100vh;
    min-height: 500px;
    margin-top: 0;
    margin-bottom: 2rem;

    > .container {
      height: 100%;
      width: 100%;
      max-width: none;
      padding: 0;

      > .row {
        height: 100%;
        width: 100%;
        max-width: none;
        margin: 0;

        > div[class*="col"] {
          padding: 0;
          position: initial;
        }
      }
    }
  }

  .hero-bg {
    background: none !important;
  }

  .hero-text {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .hero-text__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    span {
      background-color: rgba(0, 0, 0, 0.4);
      margin-bottom: 10rem;
      padding: 0.5rem;
    }

    h3 {
      color: $white;
    }
  }

  .hero-text__paragraph {
    display: none;
    top: 100%;
  }

  .hero-slider {
    position: absolute;
    bottom: 0;
  }

  .hero-video-preview {
    position: absolute;
    top: 16%;
    right: 0;
    z-index: 3;
  }
}
