@include media-breakpoint-up(md) {
  .icon-container {
    height: 10px;
  }
  
  .hero {
    background: none !important;
    margin-top: 0;
    margin-bottom: 0;
  }

  .hero-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: $hero-bg-width-md;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero-video-preview {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .hero-text__title {
    color: $black !important;

    h6 {
      color: $davys-grey;
    }

    h3 {
      margin: auto;
    }
  }

  .hero-text__paragraph {
    h4 {
      font-family: "Gill Sans", sans-serif;
      color: $davys-grey;
    }
  }
}
