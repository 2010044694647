.video-preview {
  width: 175px;
  height: 125px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 8%;
    left: 50%;
    margin-top: -17.5px;
    margin-left: -17.5px;
    background: url("../img/icon-play.svg") no-repeat center center transparent;
    background-size: cover;
  }
}
