.Toolbar {
  height: 60px;
  background-color: $white;
  z-index: 5;
  width: 50%;
  position: fixed;
  transition: background-color 100ms linear 120ms;
}

.logo {
  height: 60px;
}

@include media-breakpoint-down(sm) {
  .Toolbar {
    width: 100%;
    background-color: $white !important;
  }
  .logo {
    justify-content: center;
  }
}

@include media-breakpoint-up(md) {
  .Toolbar {
    width: 100%;
    background-color: transparent;
  }

  .logo {
    justify-content: flex-start;
  }
}
