.btn {
  border-radius: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-weight: $font-weight-medium;

  i {
    position: relative;
    top: 1px;
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// Quick monkey patch for btn-outline-dark because it's hiding text when hovered
.btn-outline-dark {
  &:hover {
    color: white !important;
  }
}

.btn-outline-claims--radio {
  width: 18vw;
  border-radius: 0;
  border-color: $gray;
}

@include media-breakpoint-down(md) {
  .btn-outline-claims--radio {
    width: 40vw;
  }
}

.btn-check:checked + .btn-outline-claims--radio {
  background-color: $gray-400;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.btn-primary {
  color: white;
}

.btn-dark {
  &:disabled,
  &.disabled {
    opacity: 0.5;
  }
}
