.ecosense-modal {
  h2 {
    font-family: "Gill Sans", sans-serif;
    font-weight: 400;
    line-height: 1.35;
    color: $dark;
  }
}

img.certs {
  max-width: 60px;
}

img.certs-v2 {
  max-width: 70px;
}

img.certs-v3 {
  max-width: 85px;
}
