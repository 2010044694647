@include media-breakpoint-up(xl) {
  .Toolbar {
    > .container {
      max-width: 100%;
      padding: 0;
    }

    .logo {
      padding-left: 5rem;
    }
  }
}