input.form-control,
select.form-control {
  &:focus {
    border-color: gray;
  }
}

.sampling-request-modal {
  h3 {
    font-family: "Dala Moa", sans-serif;
  }

  h4 {
    font-family: "Dala Moa", sans-serif;
  }
}
