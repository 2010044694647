@include media-breakpoint-up(md) {
  .container-form {
    padding: 0 10rem;
  }

  .followus {
    display: none;
  }

  .footer-logo {
    justify-content: space-between;
  }
}
