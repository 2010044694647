html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-title;
}

p,
pre,
a,
label,
li,
span {
  font-family: $font-family-base;
}

sup {
  top: -0.5rem;
}

strong {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.font-gill-sans {
  font-family: $font-family-base;
}
