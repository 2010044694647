.carousel-image {
  display: block;
  text-decoration: none;
  width: 100%;
  padding: 4px;
}

.carousel-image__image {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 90%;
  background: no-repeat center center transparent;
  background-size: cover;
}

.carousel-image__label {
  display: block;
  font-family: "Gramatika", sans-serif;
  font-weight: normal;
  color: #aaaaaa;
  font-size: $font-size-sm * 0.67;
  text-transform: uppercase;
  text-align: center;
  padding-top: 0.75rem;
  margin-bottom: 0;
  text-decoration: none;
}

.carousel-image__label:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.carousel-image--active {
  .carousel-image__image {
    border: 1px solid $white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }
}
