@include media-breakpoint-up(md) {
  .paragraph {
    p {
      line-height: 1.8;
    }
  }

  .paragraph.outside-hero {
    color: grey;
    display: none;

    h4 {
      font-weight: 500;
      font-size: 20px;
      margin: 2.5rem 0 2.5rem 0;
      line-height: 30px;
    }

    p {
      margin: 0;
      font-size: 17px;
      line-height: 30px;
    }
  }
}
