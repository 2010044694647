.colors-slider__title {
  font-family: "Gill Sans", sans-serif;
  font-weight: 500;
}

.keen-slider__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
}

.slider-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #000000;
  cursor: pointer;

  &.slider-arrow--left {
    left: 0;
  }

  &.slider-arrow--right {
    left: auto;
    right: 0;
  }

  &.slider-arrow--disabled {
    fill: #c2c2c2;
  }
}
