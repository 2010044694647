.Footer {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

ul {
  list-style: none;
  padding: 0 !important;

  li {
    font-size: 12px;
    line-height: 20px;

    a {
      color: $gray;
      text-decoration: none;

      &:hover {
        color: $gray;
        text-decoration: underline;
      }
    }
  }
}

.followus {
  h6 {
    text-align: center;
  }

  ul {
    display: flex;
    justify-content: space-around;
    margin: 0;
  }
}

.bg-gray {
  background-color: rgba(216, 216, 216, 0.3);
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  span.small {
    color: grey;
    line-height: 12px;
  }
}

.footer-award {
  background-color: black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70%;
  }

  p {
    color: white;
    font-weight: 300;
    font-family: "Gill Sans", sans-serif;
  }
}
