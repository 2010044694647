@include media-breakpoint-down(sm) {
  .container-form {
    padding: 0 2rem;
  }

  .desktop {
    display: none;
  }

  .footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    span.small {
      display: none;
    }
  }

  .footer-award {
    padding: 2rem 0;
  }
}
