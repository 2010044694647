@include media-breakpoint-up(md) {
  .colors-slider__title {
    margin-bottom: 2rem;
    color: grey;
  }

  .colors-slider__carousel {
    margin-left: -4px;
    margin-right: -4px;
    padding: 0 16px;
    position: relative;
  }

  .BrainhubCarousel__arrows {
    position: absolute;
    z-index: 10;
    background-color: black;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 100%;

    span {
      padding: 3px;
    }

    &:hover {
      background-color: black !important;
    }
  }

  .BrainhubCarousel__arrowLeft {
    left: -25px;
  }

  .BrainhubCarousel__arrowRight {
    right: -25px;
  }
}
