.paragraph {
  h4 {
    font-family: "Gill Sans", sans-serif;
    font-weight: 500;
    line-height: 1.35;
    color: $davys-grey;
    font-size: $h5-font-size;
  }

  p {
    font-family: "Gill Sans", sans-serif;
    margin: 0;
    font-size: 16px;
    line-height: 2;
  }
}
