h4 {
  font-size: $font-size-base;
  font-family: "Gill Sans", sans-serif;
  font-weight: 600;
}

h5,
h6.description {
  font-size: $font-size-sm;
  font-family: "Gill Sans", sans-serif;
  margin: 0.25rem 0 0.45rem 0;
}

h5 {
  font-weight: 600;
}

h6.description {
  font-weight: normal;
}

h6.description--light {
  color: gray;
  font-weight: lighter;
}

img.images {
  max-width: 48%;
  margin-right: 1rem;
}

.images--ecosense {
  max-width: 40%;
}

p.small {
  font-family: "Gill Sans", sans-serif;
  font-weight: 300;
}

p.italic-small {
  font-family: "Gill Sans", sans-serif;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
}

.text-very-dark-cyan {
  color: $very-dark-cyan;
  font-family: "Gill Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0.25rem 0 0.45rem;
}

.fz-title-main {
  font-size: 1rem;
  font-weight: 600;
}
