a {
  color: black;

  &:hover {
    color: black;
  }
}

.icon-container {
  height: 10px !important;
}

.hero {
  margin-top: $header-height * -1;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-text__title {
  h3 {
    font-family: "Dala Moa", sans-serif;
    font-size: 50px;
  }

  h1 {
    font-family: "Dala Moa", sans-serif;
  }
}

.hero-slider {
  width: 100%;
}

.scroll {
  animation: down 1.3s infinite;
  -webkit-animation: down 1.3s infinite;
  &::before {
    content: "";
    transform: rotate(-45deg);
  }
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

.sup {
  font-size: 50%;
}
